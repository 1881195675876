<template>
  <div id="contact">
    <b-container>
      <b-col>
        <b-row class="img-title">
          <b-col sm="4" md="2" class="" v-for="(item, index) in imageHeader" :key="index">
            <div class="header-img-list" :style="{'background-image':'url('+item.link+')'}"></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="4" style="margin-bottom: 30px;">
            <span class="prizes">Contact</span><br>
            <span class="hastagfeel">#FeelMongolia</span>
          </b-col>
          <b-col sm="12" md="8">
            <form @submit.prevent="onSubmit">
            <b-form-group label-cols="4" label-cols-lg="2" label="Your name" label-for="input-default">
              <b-form-input v-model="form.name" required></b-form-input>
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="2" label="Email" label-for="input-default">
              <b-form-input v-model="form.email" required></b-form-input>
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="2" label="Message" label-for="input-default">
              <b-form-textarea
                rows="6"
                max-rows="10"
                v-model="form.message"
                required
              ></b-form-textarea>
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="2" label-for="input-default">
              <b-button type="submit" block >SEND</b-button>
            </b-form-group>
            </form>
          </b-col>
        </b-row>
      </b-col>
    </b-container>
  </div>
</template>

<script>
import img01 from '@/assets/img/campaign/img01.jpg';
import img02 from '@/assets/img/campaign/img02.jpg';
import img03 from '@/assets/img/campaign/img03.jpg';
import img04 from '@/assets/img/campaign/img04.jpg';
import img05 from '@/assets/img/campaign/img05.jpg';
import img06 from '@/assets/img/campaign/img06.jpg';
import metLogo from '@/assets/img/campaign/MET_logo_blue.png';
import axios from 'axios';
export default {
  name: 'partners',
  data() {
    return {
      imageHeader : [
        { link : img01 },
        { link : img02 },
        { link : img03 },
        { link : img04 },
        { link : img05 },
        { link : img06 }
      ],
      metLogo : metLogo,
      form: {
        name: "",
        email: "",
        message: ""
      }
    }
  },
  methods: {
    onSubmit() {
      axios
        .post("/.netlify/functions/sendmail", this.form)
        .then(() => {
          this.$toasted.show("Thank you, your message has been sent.", {
            type: "success",
            position: "bottom-right",
            duration: 1000
          });

          this.form.name = "";
          this.form.email = "";
          this.form.message = "";
        })
        .catch(err => {
          console.error(err);
          this.$toasted.show("Something went wrong.", {
            type: "error",
            position: "bottom-right",
            duration: 1000
          });
        });
    }
  }
}
</script>

<style lang="scss" scoped>
  @media (min-width: 1200px) {
    .container {
      max-width: 1240px;
    }
  }

  #contact {
    padding-top: 230px;

    .img-title {
      padding-bottom: 200px;
      .header-img-list {
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        padding-bottom: 100%;
        margin-bottom: 10px;
      }
    }

    .prizes {
      font-weight: bold;
      font-size: 16px;
    }

    .hastagfeel {
      font-weight: bold;
      font-size: 25px;
    }

    @media (min-width: 1024px) {
      .prizes {
        font-weight: bold;
        font-size: 25px;
      }

      .hastagfeel {
        font-weight: bold;
        font-size: 36px;
      }
    }
    
    @media (min-width: 1200px) {
      .prizes {
        font-weight: bold;
        font-size: 25px;
      }

      .hastagfeel {
        font-weight: bold;
        font-size: 48px;
      }
    }  

    .form-control, .btn {
      border-radius: 0;
      border: 1px #E33E26 solid;
    }

    .btn-secondary {
      background-color: #E33E26;
      color: #FFF;
      font-weight: bold;
    }
    
  }
</style>


